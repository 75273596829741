import BoltRounded from "@mui/icons-material/BoltRounded";
import SearchRounded from "@mui/icons-material/SearchRounded";
import TableChartRounded from "@mui/icons-material/TableChartRounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import TuneRounded from "@mui/icons-material/TuneRounded";
import UpdateRounded from "@mui/icons-material/UpdateRounded";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const items = [
  {
    icon: <SearchRounded />,
    title: "Simplified Outage Identification",
    description:
      "Quickly identify network outages impacting specific generators or loads by entering a DUID, saving hours of complex data analysis.",
  },
  {
    icon: <UpdateRounded />,
    title: "Real-Time Updates",
    description:
      "Access up-to-date outage information every 30 minutes, ensuring you always have the latest data for informed decision-making.",
  },
  {
    icon: <BoltRounded />,
    title: "Enhanced Operational Efficiency",
    description:
      "Streamline operations by eliminating the need for manual CSV downloads or complex SQL queries to access critical outage information.",
  },
  {
    icon: <TouchAppRoundedIcon />,
    title: "User-Friendly Interface",
    description:
      "Intuitive design allows for seamless navigation and quick data retrieval, making it accessible for both technical and non-technical users.",
  },
  {
    icon: <TableChartRounded />,
    title: "Comprehensive NOS Analysis",
    description:
      "Explore the entire Network Outage Schedule in a searchable and filterable table format, providing deeper insights into outages and constraints.",
  },
  {
    icon: <TuneRounded />,
    title: "Customizable Search Options",
    description:
      "Tailor your search based on specific needs, whether by DUID or other criteria, offering flexibility and precision in analysis.",
  },
];

export default function Highlights() {
  return (
    <Box
      id={"highlights"}
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "grey.900",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            display="flex"
            flexDirection="row"
            sx={{
              justifyContent: "center",
              color: "text.primary",
            }}
          >
            Why use NEM&nbsp;
            <Typography
              component="small"
              variant="h4"
              gutterBottom
              sx={{ color: "primary.light" }}
            >
              Outages
            </Typography>
            ?
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            NEM Outages transforms how you access and interpret outage data with
            real-time updates, targeted DUID-based searches, and a user-friendly
            interface. Say goodbye to tedious manual processes and hello to
            streamlined operations with comprehensive NOS analysis and
            customizable search options, allowing you to make informed decisions
            quickly and accurately.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: "inherit",
                  p: 3,
                  height: "100%",
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  backgroundColor: "grey.800",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ColorModeContext, tokens, useMode } from "../../../../theme";
import Dashboard from "../../Dashboard";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: "120vh",
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundSize: "cover",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: "120vh",
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Demo() {
  // const theme = useTheme();
  const [theme2, colorMode] = useMode("dark");
  const colors = tokens(theme2.palette.mode);
  return (
    <Box
      id={"demo"}
      sx={(theme) => ({
        // backgroundColor: "hsl(220, 35%, 3%)",
        pt: { xs: 18, sm: 24 },
        pb: { xs: 6, sm: 12 },
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 1, sm: 3 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Search the AEMO NOS by DUID
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Enter a Dispatchable Unit Identifier (DUID), and change the start &
            end dates to find outages directly affecting your operations.
          </Typography>
        </Box>
        <StyledBox>
          <ColorModeContext.Provider value="dark">
            <ThemeProvider theme={theme2}>
              <CssBaseline />
              <Box
                height="120vh"
                width="lg"
                position="relative"
                sx={{ borderRadius: "15px", overflowY: "scroll" }}
              >
                <Dashboard />
              </Box>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </StyledBox>
      </Container>
    </Box>
  );
}

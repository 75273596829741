import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link as ReactLink } from "react-router-dom";

const getCachedImageByUrl = async (requestUrl) => {
  try {
    // Open the named cache (replace 'my-cache' with your cache name)
    const cache = await caches.open("marketing-page-cache-v1");

    // Find the matching request in the cache
    const matchingResponse = await cache.match(requestUrl);

    // If there's a matching response, convert it to a blob URL
    if (matchingResponse) {
      const blob = await matchingResponse.blob();
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl; // Return the image URL for the given request URL
    } else {
      // console.error(
      //   "No matching response found for the request URL:",
      //   requestUrl
      // );
      return requestUrl; // No matching request found
    }
  } catch (error) {
    console.error("Failed to retrieve cached image:", error);
    return requestUrl;
  }
};

const dashboardImage = await getCachedImageByUrl(
  process.env.PUBLIC_URL + "/img/dashboard.png"
);

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${dashboardImage})`,
  backgroundSize: "cover",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: `url(${dashboardImage})`,
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 0, sm: 0 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            Search&nbsp;NEM&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Outages
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Stay informed, stay ahead. Search for relevant network outages in
            the AEMO Network Outage Schedule (NOS) that will impact your
            generator or load.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}
          >
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ minWidth: "fit-content" }}
              component={ReactLink}
              to="/signup"
            >
              <Typography variant="h6">Search Now</Typography>
            </Button>
          </Stack>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            By clicking &quot;Search Now&quot; you agree to our&nbsp;
            <Link
              component={ReactLink}
              to="/terms-and-conditions"
              color="primary"
            >
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}

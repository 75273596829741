import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect } from "react";
import Header from "../../components/Header";
import Refresh from "../../components/Refresh";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

const NetworkOutageAll = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let { refresh, constraintSetsAll, getNetworkOutageConstraintAll } =
    useContext(DataContext);

  const callConstraints = async () => {
    getNetworkOutageConstraintAll(authTokens);

    // setConstraints(_constraintSet);
  };

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", minWidth: 75, flex: 0.75 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 0.75,
    },
    { field: "equipmentid", headerName: "ID", minWidth: 75, flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 75, flex: 0.5 },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      minWidth: 75,
      flex: 0.5,
    },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 150,
      flex: 1,
    },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    { field: "reason", headerName: "REASON", minWidth: 120, flex: 1 },
    {
      field: "resubmitreason",
      headerName: "RESUBMIT REASON",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (constraintSetsAll.length === 0) {
      callConstraints();
    } else {
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box m="5px">
      <Header
        title="All Network Outages"
        subtitle="List of All Network Outages in Latest NOS submission"
      />
      {(() => {
        if ((constraintSetsAll.length > 0) & (refresh === false)) {
          return (
            <Box
              sx={{
                width: "100%",
                "& .name-column--cell": {
                  color: colors.grey[100],
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: colors.tableheader,
                  borderBottom: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: colors.grey[200],
                },
              }}
            >
              <DataGrid
                rows={constraintSetsAll}
                columns={columns}
                density={"compact"}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      equipmentid: false,
                      description: false,
                      lastchanged: false,
                    },
                  },
                }}
              />
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return null;
        }
      })()}
    </Box>
  );
};

export default NetworkOutageAll;

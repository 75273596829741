// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Fab from "@mui/material/Fab";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { tokens } from "../../../theme";

const powerStationList =
  process.env.PUBLIC_URL + "/assets/power-stations-list.json";

const transmissionLineList =
  process.env.PUBLIC_URL + "/assets/transmission-lines-list.json";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top

    // Media query for mobile screens
    "@media (max-width: 900px)": {
      top: "12vh", // Move down on mobile screens
    },
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 0,
  },
  switch: {
    "& .Mui-checked": {
      color: "aquamarine", // Change thumb color when checked
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "aquamarine", // Change track color when checked
    },
    "& .MuiSwitch-switchBase": {
      color: "#aquamarine", // Red thumb when unchecked
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#aquamarine", // Blue thumb when checked
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#aquamarine", // Light red track when unchecked
    },
    "& .MuiSwitch-track.Mui-checked": {
      backgroundColor: "#aquamarine", // Light blue track when checked
    },
  },
}));

const SearchToZoom = () => {
  const map = useMap();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [powerStationNames, setPowerStationNames] = useState([]);
  const [transmissionLineNames, setTransmissionLineNames] = useState([]);
  const [searchNames, setSearchNames] = useState([{ name: "default" }]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTypeToggle, setSearchTypeToggle] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Search for a power station..."
  );
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [searchType, setSearchType] = useState("powerstations");
  const isFlyToInProgress = useRef(false);
  const markerRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  // Create a custom marker icon
  const customIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/4904/4904149.png", // Path to your custom marker icon
    iconSize: [44, 44], // Adjust size
    iconAnchor: [33, 44], // Anchor point to align marker (center bottom)
    popupAnchor: [0, -44], // Position of the popup relative to the icon
  });

  const labelIcon = (name) => {
    return L.divIcon({
      className: "custom-label",
      html: `<div style="
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: black; /* Dark text for readability */
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8); /* Subtle shadow for better contrast */
        white-space: nowrap;
      ">${name}</div>`,
      iconAnchor: [name.length * 2, -44], // Adjust position of label relative to marker
    });
  };
  // Custom Zoom Component
  const ZoomToFeature = ({ coordinates }) => {
    isFlyToInProgress.current = true;

    // Fly to the new location
    map.flyTo(coordinates, 13, { duration: 1 });

    // After 1 second (flyTo duration), reset the flag
    setTimeout(() => {
      isFlyToInProgress.current = false;
    }, 2500); // Match duration

    return null;
  };

  const handleSearch = () => {
    handleClose();
    const plant = searchNames.find((p) => p?.name === searchQuery);
    if (plant) {
      setSelectedCoordinates([plant.coordinates[1], plant.coordinates[0]]); // Set coordinates for zooming
    } else {
      const value =
        searchType === "powerstations" ? "Power station" : "Transmission line";
      enqueueSnackbar(`${value} not found, click on search value in list.`, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetch(powerStationList)
      .then((response) => response.json())
      .then((data) => setPowerStationNames(data["powerStationsList"]));

    fetch(transmissionLineList)
      .then((response) => response.json())
      .then((data) => setTransmissionLineNames(data["transmissionLineList"]));
  }, []);

  useEffect(() => {
    if (powerStationNames.length > 0 && transmissionLineNames.length > 0) {
      let searchList;
      if (searchType === "powerstations") {
        searchList = powerStationNames.filter((station) =>
          station.hasOwnProperty("name")
        );
      } else {
        searchList = transmissionLineNames.filter((line) =>
          line?.hasOwnProperty("name")
        );
        // searchList = [
        //   ...new Set([...powerStationNames, ...transmissionLineNames]),
        // ];
      }

      // Sort alphabetically by 'name'
      const sortedData = searchList.sort((a, b) =>
        a?.name.localeCompare(b?.name)
      );
      setSearchNames(sortedData);
    }
  }, [powerStationNames, transmissionLineNames, searchType]);

  useEffect(() => {
    const label =
      searchType === "powerstations"
        ? "Search for a power station..."
        : "Search for a transmission line...";
    const toggle = searchType === "powerstations" ? false : true;
    setSearchPlaceholder(label);
    setSearchTypeToggle(toggle);
  }, [searchType]);

  // Listen for zoom changes to dynamically add or remove labels
  useEffect(() => {
    const handleMoveEnd = () => {
      // Clear marker only if flyTo is NOT in progress
      if (!isFlyToInProgress.current) {
        setSelectedCoordinates(null);
      }
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      // map.off("moveend", handleMoveEnd);
    };
  }, [isFlyToInProgress]);

  const open = Boolean(anchorEl);
  const id = open ? "legend-popover" : undefined;

  const openSettings = Boolean(anchorElSettings);
  const idSettings = openSettings ? "legend-popover" : undefined;

  //   const [inputValue, setInputValue] = useState(""); // Tracks what the user is typing
  //   const [filteredOptions, setFilteredOptions] = useState([]); // Holds the options to display

  //   // Debounced update of the search query
  //   const debounceSearch = debounce((value) => {
  //     setSearchQuery(value); // Update the search query after a delay
  //   }, 300); // Adjust debounce delay as needed (e.g., 300ms)

  //   useEffect(() => {
  //     if (searchQuery) {
  //       // Simulate filtering or fetching options

  //       const results = searchNames
  //         .filter((station) =>
  //           station?.name.toLowerCase().includes(searchQuery.toLowerCase())
  //         )
  //         .map((station) => ({ label: station?.name }));

  //       setFilteredOptions(results);
  //     } else {
  //       const defaultList = searchNames.map((station) => ({
  //         label: station?.name,
  //       }));
  //       setFilteredOptions([]); // Clear options if the query is empty
  //     }
  //   }, [searchQuery, searchNames]);

  return (
    <Fragment>
      {/* Floating Action Button */}
      <Fab
        color="primary"
        size="small"
        className={classes.fab}
        onClick={handleClick}
      >
        <SearchIcon /> {/* Updated Icon to InfoIcon for better clarity */}
      </Fab>

      {/* Zoom to Feature */}
      {selectedCoordinates && (
        <ZoomToFeature coordinates={selectedCoordinates} />
      )}

      {/* Zoom to Feature */}
      {selectedCoordinates && (
        <Marker
          position={selectedCoordinates}
          icon={labelIcon(searchQuery)}
          ref={markerRef}
        >
          <Popup>{searchQuery}</Popup>
        </Marker>
      )}

      {/* Zoom to Feature */}
      {selectedCoordinates && (
        <Marker
          position={selectedCoordinates}
          icon={customIcon}
          ref={markerRef}
        >
          <Popup>{searchQuery}</Popup>
        </Marker>
      )}

      {/* Popover for the color legend */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: "90vh", // Limit the height of the Popover
            overflowY: "auto", // Make content scrollable if necessary
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" p={1}>
          {/* SEARCH BAR */}
          <Stack
            spacing={1}
            justifyContent="center"
            sx={{
              minWidth: 250,
              flex: 1,
              "&& .MuiInputBase-input": {
                minHeight: "20px",
                height: "1rem",
              },
            }}
          >
            <Stack direction="row" spacing={0}>
              <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
              >
                <Stack
                  spacing={1}
                  sx={{
                    minWidth: 250,
                    flex: 1,
                    "&& .MuiInputBase-input": {
                      minHeight: "20px",
                      height: "1rem",
                    },
                  }}
                >
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={searchNames.map((station) => ({
                      label: station?.name,
                    }))}
                    onChange={(e, newValue) => {
                      if (typeof newValue === "string") {
                        setSearchQuery(newValue);
                      } else if (newValue && newValue.label) {
                        setSearchQuery(newValue.label);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder={searchPlaceholder}
                        size="small"
                      />
                    )}
                    getOptionLabel={(option) => {
                      // Ensure a fallback for undefined or null labels
                      return option?.label || "(Unnamed)";
                    }}
                  />

                  {/* <Autocomplete
                    freeSolo
                    disableClearable
                    id="search-autocomplete"
                    options={filteredOptions} // Only display filtered options
                    inputValue={inputValue} // Value in the text field
                    onInputChange={(event, value) => {
                      setInputValue(value); // Update the text field immediately
                      debounceSearch(value); // Debounced update of the query
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setInputValue(newValue);
                      } else if (newValue && newValue.label) {
                        setInputValue(newValue.label);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for a power station..."
                        size="small"
                      />
                    )}
                    getOptionLabel={(option) => option?.label || "(Unnamed)"} // Ensure a fallback for undefined labels
                  /> */}
                </Stack>
              </Box>
              <Box
                display="flex"
                sx={{
                  "& .MuiInputBase-input-MuiInput-input": {
                    minWidth: "0px",
                    padding: "0px",
                    paddingRight: "0px",
                  },
                  "& .MuiSelect-icon": {
                    top: "calc(50% - 1.25em)",
                  },
                }}
              >
                {/* <BasicDateRangePicker /> */}
                <Button onClick={handleSearch} type="button" sx={{ p: 1 }}>
                  <SearchOutlinedIcon color={"action"} />
                </Button>
              </Box>
              <Button onClick={handleClickSettings} type="button" sx={{ p: 0 }}>
                <SettingsIcon color="secondary" />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Popover>

      {/* Popover for detailed content */}
      <Popover
        id={idSettings}
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack direction="row" alignItems="right" s={2} mr={3}>
          <FormControlLabel
            control={
              <Switch
                className={classes.switch}
                checked={searchTypeToggle}
                onClick={() => {
                  searchType === "powerstations"
                    ? setSearchType("transmission")
                    : setSearchType("powerstations");
                }}
              /> // Opens the popover when clicked name="gilad" />
            }
            label={
              <Typography
                variant="h6"
                color={colors.primary[100]}
                fontWeight="bold"
                mr={1}
              >
                {searchType === "powerstations"
                  ? "Search Transmission Lines Instead?"
                  : "Turn Off to Search Power Stations Only"}
              </Typography>
            }
            labelPlacement="start"
          />
        </Stack>
      </Popover>
    </Fragment>
  );
};

export default SearchToZoom;

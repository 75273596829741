import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const ConstraintTitle = ({
  constraint,
  description,
  reason,
  type,
  value,
  factor,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="10px 0px 10px 0px" alignItems="center" justifyContent="center">
      <Typography
        variant="h5"
        color={colors.blueAccent[400]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {constraint}
      </Typography>

      <Typography
        variant="body1"
        style={{ display: "block" }}
        color={colors.greenAccent[400]}
      >
        DESCRIPTION: {}
        <Typography
          variant="body1"
          style={{ display: "inline-block" }}
          sx={{ mb: "5px" }}
          color={colors.grey[100]}
        >
          {description}
        </Typography>
      </Typography>

      <Typography
        variant="body1"
        style={{ display: "block" }}
        color={colors.greenAccent[400]}
      >
        REASON: {}
        <Typography
          variant="body1"
          style={{ display: "inline-block" }}
          sx={{ mb: "5px" }}
          color={colors.grey[100]}
        >
          {reason}
        </Typography>
      </Typography>
      <Typography
        variant="body1"
        style={{ display: "block" }}
        color={colors.greenAccent[400]}
      >
        LIMIT TYPE: {}
        <Typography
          variant="body1"
          style={{ display: "inline-block" }}
          sx={{ mb: "5px" }}
          color={colors.grey[100]}
        >
          {type}
        </Typography>
      </Typography>

      <Typography
        variant="body1"
        style={{ display: "block" }}
        color={colors.greenAccent[400]}
      >
        LHS FACTOR: {}
        <Typography
          variant="body1"
          style={{ display: "inline-block" }}
          sx={{ mb: "5px" }}
          color={colors.grey[100]}
        >
          {factor}
        </Typography>
      </Typography>

      <Typography
        variant="body1"
        style={{ display: "block" }}
        color={colors.greenAccent[400]}
      >
        CONSTRAINT VALUE: {}
        <Typography
          variant="body1"
          style={{ display: "inline-block" }}
          sx={{ mb: "5px" }}
          color={colors.grey[100]}
        >
          {value}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ConstraintTitle;

import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
  let { user } = useContext(AuthContext);
  let location = useLocation(); // Get the current location (the route user tried to access)

  if (!user) {
    // If not authenticated, redirect to the login page and preserve the original route in state
    return <Navigate to="/" state={{ from: location }} />;
  }

  // If authenticated, render the requested route
  return <Outlet />;
};

export default PrivateRoute;

import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { tokens } from "../theme";

const TextLink = ({ text, link_text, link }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box mt="10px" mb="10px" display="flex">
            <Typography variant="h6" color={colors.primary[100]} sx={{ mb: "5px", mr: "5px"}}>
                {text}
            </Typography>
            <Link to={link}>
                <Typography variant="h6" color={colors.blueAccent[500]}>
                    {link_text}
                </Typography>
            </Link>
        </Box>
    )
}

export default TextLink
import "esri-leaflet"; // Import Esri Leaflet
import "leaflet/dist/leaflet.css";
import { useRef } from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { useMap } from "react-leaflet";

function calculateMidpoint(feature) {
  const coordinates = feature.geometry.coordinates;
  const linename = feature.properties.name;
  const voltage = feature.properties.capacity_kv;
  const name = `${linename} ${voltage}kV line`;

  if (coordinates.length < 2) {
    throw new Error("LineString must have at least two points.");
  }

  // Calculate total length of the line and find the midpoint
  let totalLength = 0;
  const lengths = [];

  for (let i = 0; i < coordinates.length - 1; i++) {
    const [x1, y1] = coordinates[i];
    const [x2, y2] = coordinates[i + 1];

    // Distance between two points
    const segmentLength = Math.sqrt(
      Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)
    );
    totalLength += segmentLength;
    lengths.push(segmentLength);
  }

  const midpointLength = totalLength / 2;

  // Traverse the line to find the midpoint segment
  let accumulatedLength = 0;
  for (let i = 0; i < lengths.length; i++) {
    accumulatedLength += lengths[i];
    if (accumulatedLength >= midpointLength) {
      const [x1, y1] = coordinates[i];
      const [x2, y2] = coordinates[i + 1];

      // Interpolate the midpoint within the segment
      const remainingLength = midpointLength - (accumulatedLength - lengths[i]);
      const t = remainingLength / lengths[i];

      const midPoint = [x1 + t * (x2 - x1), y1 + t * (y2 - y1)];

      return { name: name, coordinates: midPoint };
    }
  }
}

const TransmissionLines = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const featureLayerRef = useRef();
  // const [featureData, setFeatureData] = useState([]);
  // const [listTransmissionLines, setListTransmissionLines] = useState();
  // const proceesedLines = [];

  // Function to style the lines
  const getLineStyle = (feature) => {
    const { capacity_kv } = feature.properties;
    let color;
    let weight;

    if (capacity_kv <= 66) {
      color = "#77410F";
      weight = 2;
    } else if (capacity_kv <= 132) {
      color = "#E4012D";
      weight = 2.5;
    } else if (capacity_kv <= 220) {
      color = "#0100FF";
      weight = 3;
    } else if (capacity_kv <= 275) {
      color = "#E5007E";
      weight = 3.5;
    } else if (capacity_kv <= 330) {
      color = "#F2AA2E";
      weight = 4;
    } else if (capacity_kv > 330) {
      color = "#FFDE21";
      weight = 4.5;
    } else {
      color = "#E4012D";
      weight = 2.5;
    }

    return {
      color: color,
      weight: weight,
      opacity: 0.75,
    };
  };

  const handleFeatureLoad = (feature) => {
    setFeatureData((prevData) => [...prevData, feature]);
  };

  const processFeatures = () => {
    // const powerStationNames = [];

    // Add the polygon feature if hull exists for outerElements
    featureData.forEach((feature) => {
      let exists;
      const result = calculateMidpoint(feature);

      // const identifier = feature.properties?.name;
      const linename = feature.properties?.name;
      const voltage = feature.properties?.capacity_kv;
      const identifier = `${linename} ${voltage}kV line`;
      if (proceesedLines.length > 0) {
        exists = proceesedLines.some((item) => item?.name === identifier);
      } else {
        exists = false;
      }

      if (!exists) {
        // Extract points from members
        proceesedLines.push(result);
      }
    });

    // setListTransmissionLines(proceesedLines);
  };

  // useEffect(() => {
  //   if (listTransmissionLines?.length > 0) {
  //     // Convert the object to a JS module
  //     // Write to a .js file
  //     localStorage.setItem(
  //       "myData",
  //       `${JSON.stringify(listTransmissionLines)}`
  //     );

  //     console.log(`${JSON.stringify(listTransmissionLines)}`);
  //   }
  // }, [listTransmissionLines]);

  return (
    <FeatureLayer
      ref={featureLayerRef}
      url="https://services.ga.gov.au/gis/rest/services/Foundation_Electricity_Infrastructure/MapServer/2"
      // where="OWNER = 'Essential'"
      simplifyFactor={1}
      style={getLineStyle} // Apply the dynamic style function
      onEachFeature={(feature, layer) => {
        const { name, capacity_kv } = feature.properties;
        const popupContent = `
          <div>
            <strong>Name:</strong> ${name} <br/>
            <strong>Capacity:</strong> ${capacity_kv} kV <br/>
          </div>
        `;
        layer.bindPopup(popupContent);

        // Save feature properties
        // handleFeatureLoad(feature);

        // Add a click event listener to log properties when a line is clicked
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });
      }}
      eventHandlers={
        {
          // loading: () => console.log("featurelayer loading"),
          // load: () => {
          //   processFeatures();
          //   console.log("featurelayer loaded");
          // },
        }
      }
    />
  );
};

export default TransmissionLines;

import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import ConstraintTree from "../../components/constraints/ConstraintTree";
import { GanttChart } from "../../components/GanttChart";
import GanttChartLegend from "../../components/GanttChartLegend";
import Header from "../../components/Header";
import Refresh from "../../components/Refresh";
import SectionHeader from "../../components/SectionHeader";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let {
    duid,
    loading,
    refresh,
    constraints,
    setConstraints,
    loadDuidConstraintData,
    preLoadData,
  } = useContext(DataContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const genconsetidSelection = [
    ...new Set(constraints.map((constr) => constr.genconsetid)),
  ];

  const callDuidConstraintData = async () => {
    setConstraints([]);
    await loadDuidConstraintData(authTokens, duid);

    if (loading) {
      preLoadData(authTokens);
    }

    // setConstraints(_constraintSet);
  };

  const columns = [
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 100,
      flex: 1,
    },
    { field: "genconid", headerName: "CONSTRAINT", minWidth: 150, flex: 1.5 },
    { field: "duid", headerName: "DUID", minWidth: 75, flex: 0.75 },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    { field: "equipmentid", headerName: "ID", minWidth: 75, flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 50, flex: 0.5 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "risk", headerName: "RISK", minWidth: 120, flex: 1 },
    { field: "bidtype", headerName: "BIDTYPE", minWidth: 75, flex: 0.75 },
    { field: "limittype", headerName: "LIMIT TYPE", minWidth: 120, flex: 1 },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 75,
      flex: 0.75,
      hide: true,
    },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
  ];

  // useEffect(() => {
  //   callDuidConstraintData();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraints]);

  useEffect(() => {
    // Log the initial pageview
    ReactGA.send({ hitType: "dashboard", page: location.pathname });
  }, [location]);

  return (
    <Box m="5px">
      <Header
        title="Asset Dashboard"
        subtitle="Use the search bar above to get a list of Network Outage Constraints that may impact a specific DUID, Interconnector or Region"
      />
      {(() => {
        if ((constraints.length > 0) & (refresh === false)) {
          return (
            <Box m="5px">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab label="Gantt Chart" {...a11yProps(0)} />
                    <Tab label="Tree View" {...a11yProps(1)} />
                    <Tab label="Table View" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {/* GANTT CHART VIEW */}
                  <SectionHeader
                    title="Gantt Chart"
                    subtitle={`View the Constraint Sets that will impact ${duid}. Click on Tree or Table View for more details.`}
                  />
                  <GanttChartLegend />
                  <GanttChart
                    genconsetidSelection={genconsetidSelection}
                    constraints={constraints}
                    columns={columns}
                  />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  {/* TREE VIEW TABLE */}
                  <SectionHeader
                    title="Tree Dropdown View"
                    subtitle={`Select Constraint Set and specific Constraint to View More Details for NOS Impact on ${duid}`}
                  />
                  <ConstraintTree
                    genconsetidSelection={genconsetidSelection}
                    constraints={constraints}
                    columns={columns}
                  />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                  {/* TABLE VIEW */}
                  <SectionHeader
                    title="Table View"
                    subtitle={`Complete List of Constraint Sets & Ids that are likely to impact ${duid}`}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      "& .name-column--cell": {
                        color: colors.grey[100],
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: colors.tableheader,
                        borderBottom: "none",
                        fontSize: "12px",
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: colors.grey[200],
                      },
                      "& .MuiDataGrid-cell": {
                        fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
                        fontSize: "12px",
                      },
                    }}
                  >
                    <DataGrid
                      rows={constraints}
                      columns={columns}
                      density={"compact"}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            equipmentid: false,
                            description: false,
                            lastchanged: false,
                          },
                        },
                      }}
                    />
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return (
            <Typography variant="h3" color={colors.grey[100]}>
              No constraints found in Network Outage Schedule (NOS) that are
              linked to {duid}.
            </Typography>
          );
        }
      })()}
    </Box>
  );
};

export default Dashboard;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const TransmissionLineLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Power Line Colors Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Transmission Lines
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#FFDE21" }}
            ></span>
            <span>Above 330 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#F2AA2E" }}
            ></span>
            <span>330 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#E5007E" }}
            ></span>
            <span>275 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#0100FF" }}
            ></span>
            <span>220 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#E4012D" }}
            ></span>
            <span>132 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#77410F" }}
            ></span>
            <span>66 kV or Below</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TransmissionLineLegend;

import { Box, Button } from "@mui/material";
import { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import TextLink from "../../components/TextLink";
import AuthContext from "../../context/AuthContext";
import SiteMarkIconLegal from "./SiteMarkIconLegal";

const Activate = () => {
  let { verifyUser } = useContext(AuthContext);
  let { uid, token } = useParams();

  const callVerifyUser = (uid, token) => {
    verifyUser(uid, token);
  };

  useEffect(() => {
    if (uid.length !== 0) {
      callVerifyUser(uid, token);
    } else {
      // nothing
    }
    console.log(uid, token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  return (
    <Fragment>
      <Box m="20px">
        <SiteMarkIconLegal />
        {/* <Title title="Welcome to this New System!" subtitle="This platform provides insight into the decisions and operations." /> */}
        <Fragment>
          <Box px={{ xs: 1, sm: 2, md: 30, lg: 50, xl: 75 }}>
            <Header
              title="Verify your Account"
              subtitle="Please confirm your email address by clicking the Activate button below."
            />

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => callVerifyUser(uid, token)}
              >
                Activate
              </Button>
            </Box>

            <TextLink
              text="Don't have an account?"
              link_text="Sign Up"
              link="/signup"
            />
          </Box>
        </Fragment>
      </Box>
    </Fragment>
  );
};

export default Activate;

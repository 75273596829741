import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import Sitemark from "./marketing-page/components/SitemarkIcon";

export default function SiteMarkIconLegal() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0, py: 3 }}
    >
      <Box component={Link} to="/">
        <Sitemark />
      </Box>

      <Box
        component={Link}
        to="/"
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 5,
          textDecoration: "none",
        }}
      >
        <Typography
          fontSize="24px"
          fontWeight="600"
          color={colors.primary[100]}
        >
          NEM&nbsp;
        </Typography>
        <Typography
          fontSize="24px"
          fontWeight="600"
          color="hsl(210, 100%, 65%)"
        >
          Outages
        </Typography>
      </Box>
    </Box>
  );
}

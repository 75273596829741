import { alpha, Box } from "@mui/material";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
// import Sidebar from "../scenes/global/Sidebar";
import CssBaseline from "@mui/material/CssBaseline";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../scenes/dashboard_v2/theme/customizations";
import AppNavbar from "../scenes/global/AppNavbar";
import Sidebar from "../scenes/global/Sidebar";
import AppTheme from "../scenes/shared-theme/AppTheme";
import { useMode } from "../theme";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

const withLogin = (props) => {
  // const colors = tokens(theme.palette.mode);
  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
        <AppNavbar />
        {/* Main content */}
        <Box
          height="100vh"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          {props.children}
        </Box>
      </Box>
    </AppTheme>
  );
};

const withoutLogin = (theme, colorMode, props) => {
  return <>{props.children}</>;
};

const ThemeLayout = (props) => {
  let { user } = useContext(AuthContext);
  const [theme, colorMode] = useMode("dark");

  return (
    <div>{user ? withLogin(props) : withoutLogin(theme, colorMode, props)}</div>
  );
};

export default ThemeLayout;

import { Box, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../../context/AuthContext";

const initialValues = {
  email: "",
};

const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Please Enter your Email"),
});

function ForgotPassword({ open, handleClose }) {
  let { resetPassword } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    console.log(e);
    resetPassword(e);
    navigate("/");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          background: "#141b2d",
        },
        // "& .MuiBackdrop-root": {
        //   backgroundColor: "transparent", // Try to remove this to see the result
        // },
      }}
    >
      <DialogTitle sx={{ pb: 0 }}>Reset password</DialogTitle>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
              }}
            >
              {/* <DialogContentText color="primary.light">
                Enter your account&apos;s email address, and we&apos;ll send you
                a link to reset your password.
              </DialogContentText> */}
              <Typography
                variant="body2"
                fontStyle={"italic"}
                color="primary.light"
              >
                Enter your account&apos;s email address, and we&apos;ll send you
                a link to reset your password.
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
            </DialogContent>
            <DialogActions sx={{ pb: 3, px: 3 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  // variant="outlined"
                  sx={{ mr: "10px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  // sx={{ ml: "10px" }}
                  variant="contained"
                  color="success"
                  onPlay={1}
                >
                  Reset
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;

import { Fragment, useContext } from "react";
import Header from "../../components/Header";

import {
  Box,
  CssBaseline,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import SectionText from "../../components/SectionText";
import AuthContext from "../../context/AuthContext";
import { ColorModeContext, tokens } from "../../theme";
import SiteMarkIconLegal from "./SiteMarkIconLegal";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { user } = useContext(AuthContext);

  return (
    <ColorModeContext.Provider value="dark">
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Fragment>
          <Box m="20px" sx={{ pb: 5 }}>
            <Box px={{ xs: 1, sm: 2, md: 20, lg: 30, xl: 50 }}>
              {user ? <Fragment /> : <SiteMarkIconLegal />}
              <Header
                title="Privacy Policy"
                subtitle="We are committed to respecting the privacy of our users and ensuring the safe and secure management of your data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services."
              />

              <SectionText
                section="1. Collection of Personal Information"
                text="Information You Provide: We collect information you provide when you create an account, use our services, or communicate with us. This may include your name, email address, company details, and any other information you choose to provide.
            Automated Collection: We may automatically collect information about your device and how you interact with our website, such as IP address, browser type, and usage details."
              />
              <SectionText
                section="2. Use of Collected Information"
                text="Service Provision: To provide, maintain, and improve our services, including managing user accounts and processing data requests.
            Communication: To communicate with you about your account or our services.
            Analysis and Development: To analyze usage trends and preferences to improve our service, and to develop new features and offerings."
              />
              <SectionText
                section="3. Data Protection"
                text="We implement a variety of security measures to maintain the safety of your personal information. However, please note that no electronic storage method is 100% secure."
              />
              <SectionText
                section="4. Sharing of Information"
                text="Third Parties: We may share your information with third parties that perform services on our behalf, including data processing and hosting.
            Legal Requirements: We may disclose your information if required by law or if we believe in good faith that such action is necessary to comply with legal processes."
              />
              <SectionText
                section="5. Third-Party Websites"
                text="Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. Please be aware when you leave our site and read the privacy statements of other websites that collect personally identifiable information."
              />
              <SectionText
                section="6. Compliance with Laws"
                text="Australian Privacy Principles: In compliance with the Privacy Act 1988 (Cth), we adhere to the Australian Privacy Principles (APPs). These principles govern the way we collect, use, disclose, store, secure, and dispose of personal information.
            Data Security: We take reasonable steps to protect the personal information we collect from misuse, loss, unauthorized access, modification, or disclosure, in line with the requirements set by the Privacy Act and other relevant Australian laws.
            Consumer Rights: Our practices align with the consumer protection laws outlined in the Australian Consumer Law (ACL), ensuring transparency, fairness, and responsibility in our dealings with users.
            Disclosure of Information: Any disclosure of personal information to third parties will be conducted in compliance with Australian laws. We will only disclose personal information where required by law or with your explicit consent.
            Cross-border Data Transfers: Where data is transferred across borders, we will take steps to ensure that the recipients adhere to standards that are in compliance with the Australian Privacy Principles.
            Updates to Legal Compliance: We acknowledge that legal standards and requirements may evolve. As such, we will review and update our privacy practices as necessary to ensure ongoing compliance with Australian laws and regulations.
            "
              />
              <SectionText
                section="7. Changes to Privacy Policy"
                text="We reserve the right to modify this privacy policy at any time. We will notify you of any changes by posting the new privacy policy on this page. Your continued use of our website and services after such changes will constitute your acknowledgment and agreement to the modified policy."
              />
              <SectionText
                section="8. User Rights"
                text="You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us at the provided contact details."
              />
              <Box mb="30px">
                <Typography
                  variant="h6"
                  color={colors.greenAccent[400]}
                  fontWeight="bold"
                  sx={{ mb: "5px" }}
                >
                  9. Contact Information
                </Typography>
                <Typography variant="h7" color={colors.grey[100]} mr="5px">
                  If you have any questions or concerns about our Privacy Policy
                  or the practices of this site, please contact us:
                </Typography>
                <Typography
                  color={colors.greenAccent[400]}
                  variant="h7"
                  mb="20px"
                >
                  nemoutages@gmail.com
                </Typography>
              </Box>
              <Typography color={colors.grey[100]} variant="h7" mr="5px">
                Last Updated:
              </Typography>
              <Typography
                color={colors.greenAccent[400]}
                variant="h7"
                mb="20px"
              >
                2023/11/30
              </Typography>
            </Box>
          </Box>
        </Fragment>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default PrivacyPolicy;

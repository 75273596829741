import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import { Fragment, useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";
import OptionsMenu from "./sidebar/OptionsMenu";
// Icons for Sidebar
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import SidebarMenu from "./sidebar/SidebarMenu";
// Icons for Sidebar
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function TruncatedEmail({ email, maxLength = 20 }) {
  // Find the position of '@' in the email
  const atIndex = email.indexOf("@");

  // Check if email length exceeds maxLength and has '@'
  const displayText =
    email.length > maxLength && atIndex > 0
      ? `${email.slice(0, atIndex)}`
      : email;

  return <span>{displayText}</span>;
}

function Sidebar(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  let { menu, setMenu, sectionMenu, setSectionMenu } = useContext(DataContext);
  let { user, authTokens, logoutUser } = useContext(AuthContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: "none", md: "block" },
          [`& .${drawerClasses.paper}`]: {
            backgroundColor: "background.paper",
          },
        }}
      >
        <DrawerHeader>
          {open ? (
            <IconButton onClick={handleDrawerClose} size="small">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon fontSize="small" />
              ) : (
                <ChevronLeftIcon fontSize="small" />
              )}
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              size="small"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                margin: 0,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />

        <Stack
          direction="column"
          sx={{ flexGrow: 1, p: 0, justifyContent: "space-between" }}
        >
          <SidebarMenu
            open={open}
            menu={menu}
            setMenu={setMenu}
            setSectionMenu={setSectionMenu}
          />
          <Fragment>
            <Stack
              direction="row"
              justifyContent={open ? "initial" : "center"}
              sx={{
                p: 1,
                gap: 1,
                alignItems: "initial",
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              {open ? (
                <Fragment>
                  <Box sx={{ ml: "auto", textAlign: "end" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, lineHeight: "16px" }}
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                    >
                      <TruncatedEmail email={user.email} maxLength={26} />
                    </Typography>
                  </Box>
                  <OptionsMenu
                    component={
                      <Avatar
                        color="text.secondary"
                        sizes="small"
                        alt={user.name}
                        src="/static/images/avatar/6.jpg"
                        sx={{ width: 24, height: 24, color: "text.secondary" }}
                      />
                    }
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <OptionsMenu
                    component={
                      <Avatar
                        color="text.secondary"
                        sizes="small"
                        alt={user.name}
                        src="/static/images/avatar/6.jpg"
                        sx={{ width: 24, height: 24, color: "text.secondary" }}
                      />
                    }
                  />
                </Fragment>
              )}
            </Stack>
          </Fragment>
        </Stack>
      </Drawer>
    </Box>
  );
}

export default Sidebar;

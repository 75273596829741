import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="secondary" />;

const ManagePortfolio = () => {
  const [userPortfolio, setUserPortfolio] = useState([]); // State to hold the list of DUIDs in the user's portfolio
  const [selectedDUIDs, setSelectedDUIDs] = useState([]); // State to hold selected DUIDs from autocomplete
  const [completeList, setCompleteList] = useState([]);
  const [filteredDUIDs, setFilteredDUIDs] = useState([]); // State to hold filtered DUID options for autocomplete
  let { authTokens } = useContext(AuthContext);
  let { duids, setPortfolio, getPortfolio, getCompleteDUIDList } =
    useContext(DataContext);
  const { enqueueSnackbar } = useSnackbar();

  const callUpdatePortfolio = () => {
    updatePortfolio(authTokens);
  };

  // Function to update (patch) the DUIDs in the Portfolio
  const updatePortfolio = async (authTokens) => {
    try {
      const response = await fetch(`/auth/portfolio/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(authTokens.access),
        },
        body: JSON.stringify({ duids: userPortfolio }),
      });
      if (response.ok) {
        const data = await response.json();
        setPortfolio(data.duids); // Update state with the new list of DUIDs
        setUserPortfolio(data.duids);
        setSelectedDUIDs([]); // Clear the input field
        enqueueSnackbar("Portfolio updated successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          `Failed to update DUIDs: ${JSON.stringify(response.statusText)}"`,
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(`Error updating DUIDs: ${JSON.stringify(error)}"`, {
        variant: "error",
      });
    }
  };

  const getUserPortfolio = async () => {
    const _portfolio = await getPortfolio(authTokens);
    setUserPortfolio(_portfolio);
  };

  const getCompleteList = async () => {
    const _completeList = await getCompleteDUIDList(authTokens);
    setCompleteList(_completeList);
  };

  // Function to add selected DUIDs to the user's portfolio
  const addDUIDsToPortfolio = () => {
    // Filter out any DUIDs that are already in the portfolio
    const newDUIDs = selectedDUIDs.filter(
      (duid) => !userPortfolio.includes(duid)
    );
    setUserPortfolio([...userPortfolio, ...newDUIDs]); // Add the new DUIDs to the user's list
    setSelectedDUIDs([]); // Clear the selected DUIDs in the autocomplete input
  };

  // Function to remove a DUID from the user's portfolio
  const removeDUIDFromPortfolio = (duidToRemove) => {
    setUserPortfolio(userPortfolio.filter((duid) => duid !== duidToRemove)); // Remove the selected DUID
  };
  // Function to remove a DUID from the user's portfolio
  const removeAllDUIDs = () => {
    setUserPortfolio([]); // Remove the selected DUID
  };

  // Update the filtered DUIDs list to show only DUIDs not in the user's portfolio
  useEffect(() => {
    getUserPortfolio();
    getCompleteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const remainingDUIDs = duids.filter(
      (duid) => !userPortfolio.includes(duid.duid)
    );
    setFilteredDUIDs(remainingDUIDs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPortfolio, completeList]);

  return (
    <Box style={{ padding: 20, width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Manage Your Portfolio Assets
      </Typography>

      {/* Autocomplete to add new DUIDs */}
      <Autocomplete
        multiple
        disableCloseOnSelect
        value={selectedDUIDs} // Array of selected DUIDs
        onChange={(event, newValue) => setSelectedDUIDs(newValue)}
        options={filteredDUIDs.map((duid) => duid.duid)}
        getOptionLabel={(option) => option || ""} // Ensure option is a string
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Assets to Portfolio"
            variant="outlined"
            placeholder="Select DUIDs, ICs, or Regions"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        size="small"
        style={{ width: "100%", marginBottom: 5, height: "small" }}
        isOptionEqualToValue={(option, value) => option === value} // Compare string values
      />
      <Button
        variant="contained"
        color="warning"
        startIcon={<AddIcon />}
        onClick={addDUIDsToPortfolio}
        disabled={selectedDUIDs.length === 0}
      >
        Add DUIDs, ICs, or Regions
      </Button>

      {/* Display current DUIDs in a list */}
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Current Assets in Portfolio ({userPortfolio.length})
      </Typography>
      <List
        dense
        sx={{
          position: "relative",
          overflow: "auto",
          maxHeight: "40vh",
          size: "sm",
        }}
      >
        {userPortfolio
          .slice() // Create a shallow copy of the array to avoid mutating the original
          .sort((a, b) => a.localeCompare(b)) // Sort the DUIDs alphabetically
          .map((duid, index) => (
            <ListItem key={index} divider size="sm">
              <ListItemText primary={duid} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeDUIDFromPortfolio(duid)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>

      {/* Save button to save the updated portfolio */}

      <Button
        variant="contained"
        color="error"
        style={{ marginTop: 20, marginRight: 5 }}
        onClick={removeAllDUIDs}
      >
        Remove All
      </Button>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: 20 }}
        onClick={callUpdatePortfolio}
      >
        Save Portfolio
      </Button>
    </Box>
  );
};

export default ManagePortfolio;

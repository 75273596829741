import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Typography, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { Link } from "react-router-dom";
import { tokens } from "../../../../theme";
import Sitemark from "./SitemarkIcon";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 16px",
}));

export default function AppAppBar({ position = "fixed" }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position={position}
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 5,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Box component={Link} to="/">
              <Sitemark />
            </Box>

            <Box
              component={Link}
              to="/"
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 5,
                textDecoration: "none",
              }}
            >
              <Typography variant="h6" color="secondary.light">
                NEM&nbsp;
              </Typography>
              <Typography variant="h6" color="primary.light">
                Outages
              </Typography>
            </Box>

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => window.location.replace("/#features")}
              >
                Features
              </Button>
              {/* <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => window.location.replace("/#testimonials")}
              >
                Testimonials
              </Button> */}
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => window.location.replace("/#highlights")}
              >
                Highlights
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                component={Link}
                to="/demo"
              >
                Demo
              </Button>
              {/* <Button
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
                onClick={() => window.location.replace("/#faq")}
              >
                FAQ
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
                onClick={() => window.location.replace("/#blog")}
              >
                Blog
              </Button> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button
              component={Link}
              to="/login"
              color="primary"
              variant="text"
              size="small"
            >
              Login
            </Button>
            <Button
              component={Link}
              to="/signup"
              color="success"
              variant="contained"
              size="small"
            >
              Sign up
            </Button>
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => window.location.replace("/#features")}>
                  Features
                </MenuItem>
                {/* <MenuItem>Testimonials</MenuItem> */}
                <MenuItem
                  onClick={() => window.location.replace("/#highlights")}
                >
                  Highlights
                </MenuItem>
                <MenuItem component={Link} to="/demo">
                  NOS Tool Demo
                </MenuItem>
                {/* <MenuItem>FAQ</MenuItem> */}
                {/* <MenuItem>Blog</MenuItem> */}
                <MenuItem>
                  <Button
                    component={Link}
                    to="/signup"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    Sign up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                    variant="outlined"
                    fullWidth
                  >
                    Sign in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}

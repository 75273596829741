import { Box, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import Header from "../../components/Header";
import SectionHeader from "../../components/SectionHeader";
import AuthContext from "../../context/AuthContext";
import { tokens } from "../../theme";

const Account = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { user, authTokens } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const refreshNetworkTables = async (authTokens) => {
    let response = await fetch("/data/refresh_network_tables/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      enqueueSnackbar(
        "Data Updated Correctly: " + JSON.stringify(data),
        "success"
      );
    } else {
      enqueueSnackbar(
        "Something went wrong " +
          JSON.stringify(data) +
          String(response.status),
        "error"
      );
    }
  };

  const updateMMSTables = async (authTokens) => {
    let response = await fetch("/data/update/mms/all/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    // let data = await response.json();

    if (response.status === 200) {
      enqueueSnackbar("MMS Tables Updated Correctly", "success");
    } else {
      enqueueSnackbar("Something went wrong", "error");
    }
  };

  const createLatestTables = async (authTokens) => {
    let response = await fetch("/data/create/latest/all/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    // let data = await response.json();

    if (response.status === 200) {
      enqueueSnackbar("Updated Latest MMS Tables", "success");
    } else {
      enqueueSnackbar("Something went wrong", "error");
    }
  };

  const deleteLatestTables = async (authTokens) => {
    let response = await fetch("/data/delete/mms/all/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    // let data = await response.json();

    if (response.status === 200) {
      enqueueSnackbar("Large MMS Tables Deleted ", "success");
    } else {
      enqueueSnackbar("Something went wrong", "error");
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="ADMIN SETTINGS"
          subtitle="Managing the Global Application Settings"
        />
      </Box>

      {user.is_admin ? (
        <Box m="20px">
          <Box
            display="flex"
            justifyContent="start"
            ml="10px"
            mb="100px"
            alignItems="center"
          >
            <Typography color={colors.blueAccent[300]} variant="h5" pr="20px">
              Force Refresh Updating of Network Tables
            </Typography>
            <Button
              onClick={() => refreshNetworkTables(authTokens)}
              color="secondary"
              variant="contained"
            >
              Update Network Tables
            </Button>
          </Box>
          <SectionHeader
            title="UPDATE SPD & GENCON TABLES"
            subtitle="To be completed only once a month after PUBLIC_ARCHIVE files are on nemweb."
          />
          <Box
            display="flex"
            justifyContent="start"
            ml="10px"
            mb="20px"
            alignItems="center"
          >
            <Typography color={colors.blueAccent[300]} variant="h5" pr="20px">
              Step 1: Update MMS Tables from nemweb Public Archives
            </Typography>
            <Button
              onClick={() => updateMMSTables(authTokens)}
              color="secondary"
              variant="contained"
            >
              Update MMS Tables
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="start"
            ml="10px"
            mb="20px"
            alignItems="center"
          >
            <Typography color={colors.blueAccent[300]} variant="h5" pr="20px">
              Step 2: Update Latest versions of the MMS Tables with the new
              rows.
            </Typography>
            <Button
              onClick={() => createLatestTables(authTokens)}
              color="secondary"
              variant="contained"
            >
              Update Latest MMS Tables
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="start"
            ml="10px"
            mb="20px"
            alignItems="center"
          >
            <Typography color={colors.blueAccent[300]} variant="h5" pr="20px">
              Step 3: Delete Large MMS Tables.
            </Typography>
            <Button
              onClick={() => deleteLatestTables(authTokens)}
              color="secondary"
              variant="contained"
            >
              Delete MMS Network Tables
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography color={colors.blueAccent[300]} variant="h5" pr="20px">
          You are not an Admin!
        </Typography>
      )}
    </Box>
  );
};

export default Account;

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useTheme } from "@mui/material";
import Divider, { dividerClasses } from "@mui/material/Divider";
import { listClasses } from "@mui/material/List";
import ListItemIcon, { listItemIconClasses } from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import { paperClasses } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import SettingsContext from "../../../context/SettingsContext";
import { tokens } from "../../../theme";
import MenuButton from "../../dashboard_v2/components/MenuButton";

const MenuItem = styled(MuiMenuItem)({
  margin: "2px 0",
});

export default function OptionsMenu({ component }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const colors = tokens(theme.palette.mode);
  let { mode, setMode } = React.useContext(SettingsContext);
  let { user, authTokens, logoutUser } = React.useContext(AuthContext);

  const toggleColorMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();

  let callLogout = () => {
    setMode("dark");
    logoutUser();
    navigate("/");
  };

  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: "transparent" }}
      >
        {component}
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: "4px",
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: "4px -4px",
          },
        }}
      >
        <MenuItem
          style={{ color: colors.grey[200] }}
          component={Link}
          to="/account"
        >
          <ListItemText>Account Profile</ListItemText>
        </MenuItem>
        {user.is_admin ? (
          <MenuItem
            style={{ color: colors.grey[200] }}
            component={Link}
            to="/settings-admin"
          >
            <ListItemText>Admin Settings</ListItemText>
          </MenuItem>
        ) : null}
        <Divider />

        <MenuItem
          style={{ color: colors.grey[200] }}
          onClick={toggleColorMode}
          to="/terms-and-conditions"
        >
          {mode === "dark" ? (
            <LightModeOutlinedIcon fontSize="small" />
          ) : (
            <DarkModeOutlinedIcon fontSize="small" />
          )}
          &nbsp;
          <ListItemText>
            {mode === "dark" ? "Dark Mode" : "Light Mode"}
          </ListItemText>
          {/* </Typography> */}
        </MenuItem>

        <MenuItem
          style={{ color: colors.grey[200] }}
          component={Link}
          to="/terms-and-conditions"
        >
          <ListItemText>Terms of Use</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={callLogout}
          style={{ color: colors.grey[200] }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: "auto",
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-gb";
import React, { useContext } from "react";
import DataContext from "../context/DataContext";

export default function BasicDateRangePicker() {
  //   const [startdate, setStartDate] = useState(dayjs());
  //   const [enddate, setEndDate] = useState(dayjs().ad/d(7, "day"));
  let { duid, startdate, enddate, setStartDate, changeEndDate } =
    useContext(DataContext);

  const changeFinalDate = (new_enddate) => {
    changeEndDate(new_enddate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <Stack direction="row" spacing={2}>
        <DatePicker
          label="search start date"
          slotProps={{ textField: { size: "small" } }}
          value={startdate}
          onChange={(new_startdate) => setStartDate(new_startdate)}
        />
        <DatePicker
          label="search end date"
          slotProps={{ textField: { size: "small" } }}
          value={enddate}
          onChange={(new_enddate) => changeFinalDate(new_enddate)}
        />
      </Stack>
    </LocalizationProvider>
  );
}

// export default function BasicDateRangePicker() {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DateRangePicker
//         localeText={{ start: "search start date", end: "search end date" }}
//         slotProps={{ textField: { size: "small" } }}
//       />
//     </LocalizationProvider>
//   );
// }

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { tokens } from "../../theme";
import ConstraintTitle from "./ConstraintTitle";
const ConstraintTreeAllSets = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 100,
      flex: 1,
    },
    { field: "genconid", headerName: "CONSTRAINT", minWidth: 150, flex: 1.5 },
    {
      field: "constraintvalue",
      headerName: "CONSTRAINT VALUE",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "genericconstraintweight",
      headerName: "CONSTRAINT WEIGHT",
      minWidth: 100,
      flex: 1,
    },
    { field: "constrainttype", headerName: "TYPE", minWidth: 75, flex: 0.75 },
    { field: "bidtype", headerName: "BID TYPE", minWidth: 75, flex: 0.5 },
    { field: "impact", headerName: "IMPACT", minWidth: 300, flex: 3 },
    {
      field: "effectivedate",
      headerName: "EFFECTIVE DATE",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
  ];
  return (
    <SimpleTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        height: "100%",
        flexGrow: 1,
        width: "100%",
        overflowY: "auto",
        mb: "20px",
      }}
    >
      {data.genconsetidSelection.map((genconsetid, genconsetidIndex) => {
        // setRender(true)
        const genconsetid_array = data.duidConstraints.filter(
          (constr) => constr.genconsetid === genconsetid
        );

        const genconidSelection = [
          ...new Set(genconsetid_array.map((constr) => constr.genconid)),
        ];
        return (
          <TreeItem2 itemId={`${genconsetid}`} label={`${genconsetid}`}>
            {genconidSelection.map((genconid, genconidIndex) => {
              const genconset_genconid_array = genconsetid_array.filter(
                (constr) => constr.genconid === genconid
              );
              const startdateSelection = [
                ...new Set(
                  genconset_genconid_array.map((constr) => constr.effectivedate)
                ),
              ];

              return (
                <TreeItem2
                  itemId={`${genconsetid}-${genconid}`}
                  label={`${genconid}`}
                >
                  <ConstraintTitle
                    constraint={genconid}
                    description={genconset_genconid_array[0].description}
                    reason={genconset_genconid_array[0].reason}
                    type={genconset_genconid_array[0].limittype}
                    factor={genconset_genconid_array[0].factor}
                    value={genconset_genconid_array[0].constraintvalue}
                  />

                  {startdateSelection.map(
                    (effectivedate, effectivedateIndex) => {
                      const genconset_genconid_starttime_array =
                        genconset_genconid_array.filter(
                          (constr) => constr.effectivedate === effectivedate
                        );

                      return (
                        <TreeItem2
                          itemId={`${genconsetid}-${genconid}-${effectivedate}-${genconset_genconid_starttime_array[0].id}`}
                          label={`EFFECTIVE DATE: ${effectivedate} => LAST CHANGED: ${genconset_genconid_starttime_array[0].lastchanged}`}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              "& .name-column--cell": {
                                color: colors.grey[100],
                              },
                              "& .MuiDataGrid-columnHeader": {
                                backgroundColor: colors.tableheader,
                                borderBottom: "none",
                              },
                              "& .MuiDataGrid-toolbarContainer .MuiButton-text":
                                {
                                  color: colors.grey[200],
                                },
                            }}
                          >
                            <DataGrid
                              rows={genconset_genconid_starttime_array}
                              columns={columns}
                              initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    equipmentid: false,
                                    description: false,
                                    lastchanged: false,
                                  },
                                },
                              }}
                              density={"compact"}
                              slots={{ toolbar: GridToolbar }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                            />
                          </Box>
                        </TreeItem2>
                      );
                    }
                  )}
                </TreeItem2>
              );
            })}
          </TreeItem2>
        );
      })}
    </SimpleTreeView>
  );
};

export default ConstraintTreeAllSets;

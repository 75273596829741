import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { tokens } from "../../theme";
import ConstraintTitle from "./ConstraintTitle";
const ConstraintTree = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <SimpleTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        height: "100%",
        flexGrow: 1,
        width: "100%",
        overflowY: "auto",
        mb: "20px",
      }}
    >
      {data.genconsetidSelection.map((genconsetid, genconsetidIndex) => {
        const genconsetid_array = data.constraints.filter(
          (constr) => constr.genconsetid === genconsetid
        );

        const startdateSelection = [
          ...new Set(genconsetid_array.map((constr) => constr.starttime)),
        ];

        return (
          <TreeItem2 itemId={`${genconsetid}`} label={`${genconsetid}`}>
            {startdateSelection.map((starttime, starttimeIndex) => {
              const genconset_starttime_array = genconsetid_array.filter(
                (constr) => constr.starttime === starttime
              );

              const genconidSelection = [
                ...new Set(
                  genconset_starttime_array.map((constr) => constr.genconid)
                ),
              ];

              return (
                <TreeItem2
                  itemId={`${genconsetid}-${starttime}-${genconset_starttime_array[0].endtime}`}
                  label={`${starttime} => ${genconset_starttime_array[0].endtime}`}
                >
                  {genconidSelection.map((genconid, genconidIndex) => {
                    const genconset_starttime_genconid_array =
                      genconset_starttime_array.filter(
                        (constr) => constr.genconid === genconid
                      );

                    return (
                      <TreeItem2
                        itemId={`${genconsetid}-${starttime}-${genconid}-${genconset_starttime_array[0].bidtype}`}
                        label={`${genconid}`}
                      >
                        <ConstraintTitle
                          constraint={genconid}
                          description={
                            genconset_starttime_genconid_array[0].description
                          }
                          reason={genconset_starttime_genconid_array[0].reason}
                          type={genconset_starttime_genconid_array[0].limittype}
                          factor={genconset_starttime_genconid_array[0].factor}
                          value={
                            genconset_starttime_genconid_array[0]
                              .constraintvalue
                          }
                        />
                        <Box
                          sx={{
                            width: "100%",
                            "& .name-column--cell": {
                              color: colors.grey[100],
                            },
                            "& .MuiDataGrid-columnHeader": {
                              backgroundColor: colors.tableheader,
                              borderBottom: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                              color: colors.grey[200],
                            },
                          }}
                        >
                          <DataGrid
                            rows={genconset_starttime_genconid_array}
                            columns={data.columns}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  equipmentid: false,
                                  description: false,
                                  lastchanged: false,
                                },
                              },
                            }}
                            density={"compact"}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                          />
                        </Box>
                      </TreeItem2>
                    );
                  })}
                </TreeItem2>
              );
            })}
          </TreeItem2>
        );
      })}
    </SimpleTreeView>
  );
};

export default ConstraintTree;

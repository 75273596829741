import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Sitemark from "./marketing-page/components/SitemarkIcon";

export default function SiteMarkIcon() {
  return (
    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
      <Box component={Link} to="/">
        <Sitemark />
      </Box>

      <Box
        component={Link}
        to="/"
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 5,
          textDecoration: "none",
        }}
      >
        <Typography variant="h6" color="secondary.light">
          NEM&nbsp;
        </Typography>
        <Typography variant="h6" color="primary.light">
          Outages
        </Typography>
      </Box>
    </Box>
  );
}

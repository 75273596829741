import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { tokens } from "../../theme";
import ConstraintTitle from "./ConstraintTitle";
const ConstraintTreeGanttDuid = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const duidSelection = [
    ...new Set(data.constraints.map((constr) => constr.duid)),
  ];

  return (
    <SimpleTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        height: "100%",
        flexGrow: 1,
        width: "100%",
        overflowY: "auto",
        mb: "20px",
      }}
    >
      {duidSelection.map((duid) => {
        const duidConstraints = data.constraints.filter(
          (constr) => constr.duid === duid
        );

        const genconidSelection = [
          ...new Set(duidConstraints.map((constr) => constr.genconid)),
        ];

        return (
          <TreeItem2 itemId={`${duid}`} label={`${duid}`}>
            {genconidSelection.map((genconid, genconidIndex) => {
              const duidConstraint = duidConstraints.filter(
                (constr) => constr.genconid === genconid
              );

              return (
                <TreeItem2 itemId={`${duid}-${genconid}`} label={`${genconid}`}>
                  <ConstraintTitle
                    constraint={genconid}
                    description={duidConstraint[0].description}
                    reason={duidConstraint[0].reason}
                    type={duidConstraint[0].limittype}
                    factor={duidConstraint[0].factor}
                    value={duidConstraint[0].constraintvalue}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      "& .name-column--cell": {
                        color: colors.grey[100],
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: colors.tableheader,
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: colors.grey[200],
                      },
                    }}
                  >
                    <DataGrid
                      rows={duidConstraint}
                      columns={data.columns}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            starttime: false,
                            endtime: false,
                            genconsetid: false,
                            genconid: false,
                            description: false,
                            lastchanged: false,
                          },
                        },
                      }}
                      density={"compact"}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </Box>
                </TreeItem2>
              );
            })}
          </TreeItem2>
        );
      })}
    </SimpleTreeView>
  );
};

export default ConstraintTreeGanttDuid;

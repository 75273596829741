import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { GanttChart } from "../../components/GanttChart";
import GanttChartLegendNOS from "../../components/GanttChartLegendNOS";
import Header from "../../components/Header";
import Refresh from "../../components/Refresh";
import SectionHeader from "../../components/SectionHeader";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const NetworkOutageSet = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let { refresh, constraintSets, getNetworkOutageConstraintSet } =
    useContext(DataContext);
  const [value, setValue] = useState(0);

  const genconsetidSelection = [
    ...new Set(constraintSets.map((constr) => constr.genconsetid)),
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callConstraints = async () => {
    getNetworkOutageConstraintSet(authTokens);

    // setConstraints(_constraintSet);
  };

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", minWidth: 75, flex: 0.75 },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 150,
      flex: 1,
    },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 1,
    },
    { field: "equipmentid", headerName: "ID", minWidth: 75, flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 75, flex: 0.5 },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "reason", headerName: "REASON", minWidth: 150, flex: 1 },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (constraintSets.length === 0) {
      callConstraints();
    } else {
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box m="5px">
      <Header
        title="Complete Constraint Set"
        subtitle="List of Network Outage Constraints Only in Latest NOS submission"
      />

      {(() => {
        if ((constraintSets.length > 0) & (refresh === false)) {
          return (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Gantt Chart" {...a11yProps(0)} />
                  <Tab label="Table View" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {/* GANTT CHART VIEW */}
                <SectionHeader
                  title="Gantt Chart"
                  subtitle={`Complete List of Constraint Sets in Latest Network Outage Schedule Update`}
                />
                <GanttChartLegendNOS />
                <GanttChart
                  genconsetidSelection={genconsetidSelection}
                  constraints={constraintSets}
                  columns={columns}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                {/* TABLE VIEW */}
                <SectionHeader
                  title="Table View"
                  subtitle={`Complete List of Constraint Sets in Latest Network Outage Schedule Update`}
                />

                <Box
                  sx={{
                    width: "100%",
                    "& .name-column--cell": {
                      color: colors.grey[100],
                    },
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: colors.tableheader,
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: colors.grey[100],
                    },
                  }}
                >
                  <DataGrid
                    rows={constraintSets}
                    columns={columns}
                    density={"compact"}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          equipmentid: false,
                          description: false,
                          lastchanged: false,
                        },
                      },
                    }}
                  />
                </Box>
              </CustomTabPanel>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return null;
        }
      })()}
    </Box>
  );
};

export default NetworkOutageSet;

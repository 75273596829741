import "esri-leaflet"; // Import Esri Leaflet
import "leaflet/dist/leaflet.css";
import { useRef, useEffect } from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { useMap } from "react-leaflet";
import L, { Icon } from "leaflet";

// Define custom icons for each asset type
const getPoleIcon = (asset_type) => {
  let iconUrl;

  switch (asset_type) {
    case "Pole":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/686/686700.png";
      break;
    case "Tower":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/18177/18177242.png";
      break;
    case "Pole w Ground Stay":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/1281/1281188.png";
      break;
    default:
      iconUrl = "https://cdn-icons-png.flaticon.com/512/686/686700.png";
  }

  return new Icon({
    iconUrl,
    iconSize: [25, 25],
    iconAnchor: [12, 12],
    popupAnchor: [0, -10],
  });
};

const TransmissionPoles = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const featureLayerRef = useRef();
  const labelLayersRef = useRef([]); // Store each feature layer reference here
  const labelMarkersRef = useRef([]); // Store label markers here

  // Function to add labels with outlined text to lines at intervals
  const addPoleLabels = (layer, feature) => {
    if (!feature.properties || !feature.properties.SITE_LABEL) return; // Skip if properties or OPERATING_VOLTAGE is missing

    const { SITE_LABEL } = feature.properties;
    const lineColor = "black";

    // Only add labels if the map zoom level is 17 or higher
    if (map.getZoom() >= 21 && SITE_LABEL) {
      const position = layer.getLatLng();

      const labelIcon = L.divIcon({
        className: "voltage-label",
        html: `<div style="
        color: white;
        font-weight: bold;
        font-size: 14px;
        white-space: nowrap;
        transform-origin: top;
        text-shadow: 
            -1px -1px 0 ${lineColor}, 
            1px -1px 0 ${lineColor}, 
            -1px  1px 0 ${lineColor}, 
            1px  1px 0 ${lineColor};
        ">${SITE_LABEL}</div>`,
        iconSize: [100, 20],
      });

      const labelMarker = L.marker(position, {
        icon: labelIcon,
        interactive: false,
      }).addTo(map);
      labelMarkersRef.current.push(labelMarker);
    }
  };

  // Remove all label markers from the map
  const removeAllLabels = () => {
    labelMarkersRef.current.forEach((labelMarker) => {
      map.removeLayer(labelMarker);
    });
    labelMarkersRef.current = [];
  };

  // Add or remove labels based on zoom level
  const updateLabelsBasedOnZoom = () => {
    const currentZoom = map.getZoom();
    if (currentZoom >= 21) {
      // Apply labels to each stored layer if zoom level >= 17
      labelLayersRef.current.forEach((layerObj) => {
        addPoleLabels(layerObj.layer, layerObj.feature);
      });
    } else {
      removeAllLabels(); // Remove labels at lower zoom levels
    }
  };

  // Listen for zoom changes to dynamically add or remove labels
  map.on("zoomend", updateLabelsBasedOnZoom);

  return (
    <FeatureLayer
      ref={featureLayerRef}
      url="https://services-ap1.arcgis.com/ug6sGLFkytbXYo4f/arcgis/rest/services/LUAL_Poles_Feature_Public/FeatureServer/0"
      simplifyFactor={1}
      pointToLayer={(feature, latlng) => {
        const asset_type = feature.properties.ASSET_TYPE;
        return new L.Marker(latlng, { icon: getPoleIcon(asset_type) });
      }}
      onEachFeature={(feature, layer) => {
        const { ASSET_TYPE, OWNER, OBJECTID, SITE_LABEL } = feature.properties;

        const popupContent = `
            <div>
              <strong>ASSET_TYPE:</strong> ${ASSET_TYPE} <br/>
              <strong>OWNER:</strong> ${OWNER} <br/>
              <strong>SITE_LABEL:</strong> ${SITE_LABEL || "N/A"} <br/>
              <strong>OBJECTID:</strong> ${OBJECTID || "N/A"} <br/>
            </div>
          `;
        layer.bindPopup(popupContent);

        // Store each feature layer reference in labelLayersRef
        labelLayersRef.current.push({ layer, feature });

        // Add a click event listener to log properties when a line is clicked
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });

        // Initially add labels if the zoom level is already >= 17
        if (map.getZoom() >= 21) {
          addPoleLabels(layer, feature);
        }
      }}
      eventHandlers={
        {
          // loading: () => {
          //   console.log("featurelayer loading");
          // },
          // load: () => {
          //   console.log("featurelayer loaded");
          // },
        }
      }
    />
  );
};

export default TransmissionPoles;

import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";

const ZoomedPowerStations = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const [powerStations, setPowerStations] = useState();
  const [powerStationsActual, setPowerStationsActual] = useState();
  const featureLayerRef = useRef();
  const labelLayersRef = useRef([]); // Store each feature layer reference here
  const labelMarkersRef = useRef([]); // Store label markers here

  const fetchPowerPlants = async (bounds, zoom) => {
    const { southWest, northEast } = bounds;

    const overpassQuery = `
      [out:json][timeout:25];
      (
        node["power"="plant"](${southWest.lat},${southWest.lng},${northEast.lat},${northEast.lng});
        way["power"="plant"](${southWest.lat},${southWest.lng},${northEast.lat},${northEast.lng});
        relation["power"="plant"](${southWest.lat},${southWest.lng},${northEast.lat},${northEast.lng});
      );
      out meta;
    >;
    out meta qt;
    `;

    const response = await fetch("https://overpass-api.de/api/interpreter", {
      method: "POST",
      body: new URLSearchParams({ data: overpassQuery }),
    });

    const osmData = await response.json();

    // Step 1: Identify used node IDs
    const usedNodeIds = new Set();

    osmData.elements.forEach((element) => {
      if (element.type === "way" || element.type === "relation") {
        if (element.nodes) {
          element.nodes.forEach((nodeId) => usedNodeIds.add(nodeId));
        }
      }
    });

    // Step 2: Convert OSM data to GeoJSON
    const geoJsonData = {
      type: "FeatureCollection",
      features: osmData.elements
        .map((element) => {
          if (element.type === "node" && !usedNodeIds.has(element.id)) {
            // Plot node only if it has not been used in a way or relation
            return {
              type: "Feature",
              properties: {
                id: element.id,
                ...element.tags,
                markerOptions: {
                  radius: 7.5, // Circle marker size (15px diameter)
                  fillColor: "#F88C00", //"#D5B60A",
                  color: "black", // Border color
                  weight: 1, // Border width
                  fillOpacity: 0.5, // Transparency
                },
              },
              geometry: {
                type: "Point",
                coordinates: [element.lon, element.lat],
              },
            };
          } else if (element.type === "way" || element.type === "relation") {
            // Handle ways and relations
            if (element.nodes) {
              const coordinates = element.nodes
                .map((nodeId) => {
                  const node = osmData.elements.find(
                    (el) => el.id === nodeId && el.type === "node"
                  );
                  if (node) {
                    return [node.lon, node.lat];
                  }
                  return null;
                })
                .filter(Boolean);

              if (
                coordinates.length > 0 &&
                element.tags?.landuse !== "farmland"
              ) {
                return {
                  type: "Feature",
                  properties: {
                    id: element.id,
                    ...element.tags,
                  },
                  geometry: {
                    type: "Polygon",
                    coordinates: [coordinates],
                  },
                };
              }
            }
          }
          return null;
        })
        .filter(Boolean),
    };

    return geoJsonData;
  };

  const handleMapMove = async () => {
    const bounds = map.getBounds();
    const zoom = map.getZoom();

    const geoJsonData = await fetchPowerPlants(
      {
        southWest: bounds.getSouthWest(),
        northEast: bounds.getNorthEast(),
      },
      zoom
    );

    setPowerStations(geoJsonData);
  };

  useEffect(() => {
    // Debounced version of handleMapMove
    const debouncedHandleMapMove = debounce(handleMapMove, 2000); // 500ms delay
    handleMapMove(); //first load
    // Attach debounced listener
    map.on("moveend", debouncedHandleMapMove);

    // Cleanup listener on unmount
    return () => {
      map.off("moveend", debouncedHandleMapMove);
    };
  }, [map]);

  return (
    <>
      {powerStations && (
        <GeoJSON
          data={powerStations}
          pointToLayer={(feature, latlng) => {
            if (
              feature.geometry.type === "Point" &&
              feature.properties.markerOptions
            ) {
              // Return a custom styled CircleMarker
              return L.circleMarker(latlng, feature.properties.markerOptions);
            }
          }}
          style={(feature) => {
            if (feature.geometry.type === "Polygon") {
              return {
                color: "red", // Border color
                weight: 2, // Border width
                fillOpacity: 0.4, // Transparency inside the polygon
              };
            }
            return {};
          }}
          onEachFeature={(feature, layer) => {
            const { id, name, operator, power, manufacturer, ref } =
              feature.properties;
            if (power === "plant") {
              const popupContent = `
              <div>
                <strong>Operator</strong> ${operator || ""} <br/>
                <strong>Name:</strong> ${name || ""} <br/>
                <strong>Fuel:</strong> ${
                  feature.properties["plant:source"] || ""
                } <br/>
                <strong>Rating:</strong> ${
                  feature.properties["plant:output:electricity"] || ""
                } <br/>
              </div>
            `;
              layer.bindPopup(popupContent);
            } else if (power === "generator") {
              const popupContent = `
              <div>
                <strong>Operator</strong> ${operator || ""}  <br/>
                <strong>Generator Type:</strong> ${
                  feature.properties["generator:method"] || ""
                } <br/>
                <strong>Manufacturer:</strong> ${manufacturer || ""} <br/>
                <strong>Manufacturer Type:</strong> ${
                  feature.properties["manufacturer:type"] || ""
                }  <br/>
                <strong>Generator Rating:</strong> ${
                  feature.properties["generator:output:electricity"] || ""
                } <br/>
                <strong>Generator Reference:</strong> ${ref || ""}  <br/>
          
              </div>
            `;
              layer.bindPopup(popupContent);
            }

            // Add a click event listener to log properties when a line is clicked
            layer.on("click", () => {
              console.log("Clicked feature properties:", feature.properties);
            });
          }}
        />
      )}
    </>
  );
};

export default ZoomedPowerStations;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const EquipmentLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Power Station Icons Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Transmission Equipment
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/15152/15152295.png"
              alt="Substation"
            />
            <span>Substation</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/18177/18177242.png"
              alt="Transmission Tower"
            />
            <span>Transmission Tower</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/1281/1281188.png"
              alt="Transmission Pole w/ Ground Stay"
            />
            <span>Transmission Pole w/ Ground Stay</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/686/686700.png"
              alt="Transmission Pole"
            />
            <span>Transmission Pole</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/5112/5112895.png"
              alt="Pillar"
            />
            <span>Pillar</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EquipmentLegend;

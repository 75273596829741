import { Box, Stack } from "@mui/material";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Header from "../scenes/global/Header";

const withLogin = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          alignItems: "center",
          flexGrow: 1,
          mx: 3,
          pb: 5,
          mt: { xs: 8, md: 0 },
        }}
      >
        <div class="container-fluid g-0">
          <Header />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            {props.children}
          </Box>
        </div>
      </Stack>
    </Box>
  );
};

const withoutLogin = (props) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      {props.children}
    </Box>
  );
};

const Layout = (props) => {
  let { user } = useContext(AuthContext);

  return <div>{user ? withLogin(props) : withoutLogin(props)}</div>;
};

export default Layout;

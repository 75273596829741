import { Fade } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export default function Refresh(refresh) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <Box sx={{ height: 40 }}>
        <Fade
          in={refresh}
          style={{
            transitionDelay: refresh ? "50ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress color="secondary" />
        </Fade>
      </Box>
    </Box>
  );
}

// import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";

const getCachedImageByUrl = async (requestUrl) => {
  try {
    // Open the named cache (replace 'my-cache' with your cache name)
    const cache = await caches.open("marketing-page-cache-v1");

    // Find the matching request in the cache
    const matchingResponse = await cache.match(requestUrl);

    // If there's a matching response, convert it to a blob URL
    if (matchingResponse) {
      const blob = await matchingResponse.blob();
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl; // Return the image URL for the given request URL
    } else {
      console.error(
        "No matching response found for the request URL:",
        requestUrl
      );
      return requestUrl; // No matching request found
    }
  } catch (error) {
    console.error("Failed to retrieve cached image:", error);
    return requestUrl;
  }
};

const StyledBox = styled("div")(({ theme, key, src, display }) => ({
  ke: { key },
  alignSelf: "center",
  width: "95%",
  height: "95%",
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  display: { display },
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(1),
    height: "95%",
    width: "95%",
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: `url(${src})`,
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

const items = [
  {
    icon: <SearchRoundedIcon />,
    title: "Search by DUID, Interconnector or Region",
    description:
      "Enter a Dispatchable Unit Identifier (DUID), Interconnector or Region to find outages directly affecting your operations.",
    imgFile: await getCachedImageByUrl(
      process.env.PUBLIC_URL + "/img/duidsearch.png"
    ),
  },
  {
    icon: <ViewQuiltRoundedIcon />,
    title: "Portfolio Overview",
    description:
      "Add your assets (DUIDs, Interconnectors, Regions), to your portfolio and view aggregate impact of network outages on your assets.",
    imgFile: await getCachedImageByUrl(
      process.env.PUBLIC_URL + "/img/portfoliooverview.png"
    ),
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Latest Constraints at a Glance",
    description:
      "Quickly search for the latest constraints associated with a specific DUID, beyond what is present in the latest NOS.",
    imgFile: await getCachedImageByUrl(
      process.env.PUBLIC_URL + "/img/constraintsearch.png"
    ),
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: "Comprehensive NOS Access",
    description:
      "Create an account to explore the entire Network Outage Schedule in a searchable, filterable table format.",
    imgFile: await getCachedImageByUrl(
      process.env.PUBLIC_URL + "/img/outagesearch.png"
    ),
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      id={"features"}
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
          overflow: "auto",
        }}
      >
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          borderWidth: 0,
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            height: "350px",
            width: "100%",
            position: "relative",
            justifyContent: "center",
            justifyItems: "center",
            borderRadius: "15px",
            overflow: "hidden",
          }}
        >
          {items.map(({ imgFile }, index) => {
            return selectedItemIndex === index ? (
              <StyledBox
                id={`${index}`}
                key={index}
                src={imgFile}
                display={selectedItemIndex === index ? "none" : "block"}
              />
            ) : null;
          })}
        </Box>
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imgFile: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <Typography
          component="h3"
          variant="h4"
          gutterBottom
          display="flex"
          flexDirection="row"
          sx={{
            color: "text.primary",
          }}
        >
          Key Benefits of NEM&nbsp;
          <Typography
            component="small"
            variant="h4"
            gutterBottom
            sx={{ color: "primary.light" }}
          >
            Outages
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.secondary", mb: { xs: 2, sm: 4 } }}
        >
          NEM Outages offers targeted DUID searches, comprehensive access to the
          Network Outage Schedule, and instant visibility into the latest
          constraints, providing a streamlined and efficient way to manage
          outage information.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "action.selected",
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: 1,
                      textAlign: "left",
                      textTransform: "none",
                      color: "text.secondary",
                    },
                    selectedItemIndex === index && {
                      color: "text.primary",
                    },
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
            height: "var(--items-image-height)",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              borderWidth: 0,
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                justifyContent: "center",
                justifyItems: "center",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              {items.map(({ imgFile }, index) => {
                return selectedItemIndex === index ? (
                  <StyledBox
                    id={`${index}`}
                    key={index}
                    src={imgFile}
                    display={selectedItemIndex === index ? "none" : "block"}
                  />
                ) : null;
              })}
            </Box>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}

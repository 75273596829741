import { Box, Button, Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import Header from "../../components/Header";
import AuthContext from "../../context/AuthContext";

const initialValues = {
  name: "",
  email: "",
  password: "",
  re_password: "",
  is_admin: false,
};

// const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
const passwordRegExp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;

const userSchema = yup.object().shape({
  name: yup.string().required("Please Enter a username"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Please Enter your Email"),
  password: yup
    .string()
    .matches(
      passwordRegExp,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
    )
    .required("Please Enter your password"),
  re_password: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  is_admin: yup.string(),
  // contact: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
  // address1: yup.string().required("required"),
});

const CreateUserForm = () => {
  //   const theme = useTheme();
  let { authTokens, signupAdminUser } = useContext(AuthContext);
  const isNonMobile = useMediaQuery("(min-width:600)");
  const [checked, setChecked] = React.useState(false);

  const handleChangeClick = (e) => {
    checked ? setChecked(false) : setChecked(true);
  };

  const handleFormSubmit = (e) => {
    // values.is_admin = checked
    signupAdminUser(authTokens, e);
  };

  return (
    <Box px={{ xs: 1, sm: 2, md: 20, lg: 40, xl: 65 }}>
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              class="container"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Full Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && errors.password}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Confirm Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.re_password}
                    name="re_password"
                    error={!!touched.re_password && errors.re_password}
                    helperText={touched.re_password && errors.re_password}
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>

                <Grid item xs={6}>
                  {/* <Typography>
                                    Is Admin? <Checkbox onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.is_admin} 
                                    name="is_admin"
                                     />
                                </Typography> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onClick={handleChangeClick}
                        onChange={handleChange}
                      />
                    }
                    label="Is Admin?"
                    type="checkbox"
                    value={values.is_admin}
                    name="is_admin"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Create New User
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateUserForm;

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { Fragment } from "react";
import AppTheme from "../shared-theme/AppTheme";
import AppAppBar from "./marketing-page/components/AppAppBar";
import Demo from "./marketing-page/components/Demo";
import Footer from "./marketing-page/components/Footer";
export default function DemoPage() {
  return (
    <>
      <AppTheme>
        <CssBaseline enableColorScheme />
        <AppAppBar position="fixed" />
        <Fragment>
          <Divider />
          <Demo />
          <Footer />
        </Fragment>
      </AppTheme>
    </>
  );
}

import "esri-leaflet"; // Import Esri Leaflet
import "leaflet/dist/leaflet.css";
import { useRef, useEffect } from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { useMap } from "react-leaflet";
import L, { Icon } from "leaflet";

// Define custom icons for each asset type
const getPoleIcon = (asset_type) => {
  let iconUrl;

  switch (asset_type) {
    case "Pole":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/686/686700.png";
      break;
    case "Tower":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/18177/18177242.png";
      break;
    case "Pole w Ground Stay":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/1281/1281188.png";
      break;
    default:
      iconUrl = "https://cdn-icons-png.flaticon.com/512/686/686700.png";
  }

  return new Icon({
    iconUrl,
    iconSize: [25, 25],
    iconAnchor: [12, 12],
    popupAnchor: [0, -10],
  });
};

const ExclusionZones = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const featureLayerRef = useRef();

  // Function to style the lines
  const getLineStyle = (feature) => {
    return {
      color: "yellow",
      weight: 2,
      opacity: 0.75,
      dashArray: "10, 5",
    };
  };
  return (
    <FeatureLayer
      ref={featureLayerRef}
      url="https://services-ap1.arcgis.com/ug6sGLFkytbXYo4f/arcgis/rest/services/LUAL_Exclusion_Zone_Feature_Public/FeatureServer/0"
      simplifyFactor={1}
      style={getLineStyle} // Apply the dynamic style function
      onEachFeature={(feature, layer) => {
        const { ASSET_TYPE, OWNER, OBJECTID, SITE_LABEL } = feature.properties;

        // Add a click event listener to log properties when a line is clicked
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });
      }}
      eventHandlers={
        {
          // loading: () => {
          //   console.log("featurelayer loading");
          // },
          // load: () => {
          //   console.log("featurelayer loaded");
          // },
        }
      }
    />
  );
};

export default ExclusionZones;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  dashedBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
    border: "2px dashed", // Dashed border for dashed lines
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const DistributionLineLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Distribution Line Colors Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Distribution Lines
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "blue" }}
            ></span>
            <span>High Voltage (HV) Overhead</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.dashedBox}
              style={{ backgroundColor: "blue" }}
            ></span>
            <span>High Voltage (HV) Underground</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "purple" }}
            ></span>
            <span>Low Voltage (LV) Overhead</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.dashedBox}
              style={{ backgroundColor: "purple" }}
            ></span>
            <span>Low Voltage (LV) Underground</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DistributionLineLegend;

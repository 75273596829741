import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { useEffect, useMemo, useState } from "react";
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import MarkerCluserGroup from "react-leaflet-cluster";
import Devices from "./components/features/Devices";
import ExclusionZones from "./components/features/ExclusionZones";
import TransmissionPoles from "./components/features/TransmissionPoles";
import MapLegend from "./components/MapLegend";
import NetworkHV from "./components/powerlines/NetworkHV";
import NetworkLV from "./components/powerlines/NetworkLV";
import NetworkTR from "./components/powerlines/NetworkTR";
import SearchToZoom from "./components/SearchToZoom";
import PowerStations from "./components/stations/PowerStations";
import ZoomedPowerStations from "./components/stations/ZoomedPowerStations";
import Substations from "./components/transmission/Substations";
import TransmissionLines from "./components/transmission/TransmissionLines";
import "./styles.css";

// Fix the default icon
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const transmissionLines =
  process.env.PUBLIC_URL + "/assets/electricity-transmission-lines.geojson";
const geoDataRaw =
  process.env.PUBLIC_URL + "/assets/major-power-stations.geojson";
const powerStationList =
  process.env.PUBLIC_URL + "/assets/power-stations-list.json";

const MapWithPowerLines = ({ powerLines }) => {
  const map = useMap(); // Access the map instance
  const [nemonly, setNemonly] = useState(powerLines);
  const [filteredPowerLines, setFilteredPowerLines] = useState(nemonly);

  useEffect(() => {
    if (!powerLines || !powerLines.features) return;

    // Define bounding boxes for WA and NT
    // Western Australia: Longitude between 112 and 129, Latitude between -36 and -10
    const waBoundingBox = {
      minLon: 112.0,
      maxLon: 129.0,
      minLat: -36.0,
      maxLat: -10.0,
    };

    // Northern Territory: Longitude between 129 and 138, Latitude between -26 and -10
    const ntBoundingBox = {
      minLon: 129.0,
      maxLon: 138.0,
      minLat: -26.0,
      maxLat: -10.0,
    };

    // Define a small bounding box for the part of Mount Isa to exclude
    const mountIsaBoundingBox = {
      minLon: 136.0,
      maxLon: 141.0,
      minLat: -22.0,
      maxLat: -18.0,
    };

    // Function to check if a coordinate is within a bounding box
    const isWithinBoundingBox = (coordinates, boundingBox) => {
      const [lon, lat] = coordinates;
      return (
        lon >= boundingBox.minLon &&
        lon <= boundingBox.maxLon &&
        lat >= boundingBox.minLat &&
        lat <= boundingBox.maxLat
      );
    };

    // Filter out features in WA and NT
    const filteredFeatures = powerLines.features.filter((feature) => {
      // Assuming coordinates are in the format [longitude, latitude]
      const coordinates = feature.geometry.coordinates[0];

      // Check if the feature's coordinates fall within WA or NT bounding boxes
      // if (
      //   isWithinBoundingBox(coordinates, waBoundingBox) ||
      //   isWithinBoundingBox(coordinates, ntBoundingBox) ||
      //   isWithinBoundingBox(coordinates, mountIsaBoundingBox)
      // ) {
      //   return false; // Exclude this feature
      // }

      return true; // Include this feature
    });

    // Set the filtered power lines data
    setNemonly({ ...powerLines, features: filteredFeatures });
  }, [powerLines]);

  useEffect(() => {
    // Function to filter power lines based on zoom level
    const filterPowerLinesByZoom = () => {
      const zoomLevel = map.getZoom();

      if (!nemonly || !nemonly.features) return;

      let filteredData;

      // Adjust filtering logic based on your desired zoom levels
      if (zoomLevel <= 5) {
        // Only show power lines with capacity greater than or equal to 275 kV
        filteredData = {
          ...nemonly,
          features: nemonly.features.filter(
            (feature) => feature.properties.capacity_kv >= 275
          ),
        };
      } else if (zoomLevel > 5 && zoomLevel <= 6) {
        // Show power lines with capacity greater than or equal to 220 kV
        filteredData = {
          ...nemonly,
          features: nemonly.features.filter(
            (feature) => feature.properties.capacity_kv >= 220
          ),
        };
      } else {
        // Show all power lines
        filteredData = nemonly;
      }

      setFilteredPowerLines(filteredData);
    };

    // Initial filtering
    filterPowerLinesByZoom();

    // Listen for zoom changes and update the filtering
    map.on("zoomend", filterPowerLinesByZoom);

    // Cleanup the event listener on component unmount
    return () => {
      if (map) {
        map.off("zoomend", filterPowerLinesByZoom);
      }
    };
  }, [map, nemonly]);

  // Function to style the lines
  const getLineStyle = (feature) => {
    const { capacity_kv } = feature.properties;
    let color;
    let weight;

    if (capacity_kv <= 66) {
      color = "#77410F";
      weight = 1;
    } else if (capacity_kv <= 132) {
      color = "#E4012D";
      weight = 1.5;
    } else if (capacity_kv <= 220) {
      color = "#0100FF";
      weight = 2;
    } else if (capacity_kv <= 275) {
      color = "#E5007E";
      weight = 2.5;
    } else if (capacity_kv <= 330) {
      color = "#F2AA2E";
      weight = 3;
    } else {
      color = "#FFDE21";
      weight = 3.5;
    }

    return {
      color: color,
      weight: weight,
      opacity: 0.75,
    };
  };

  return (
    filteredPowerLines && (
      <GeoJSON
        key={JSON.stringify(filteredPowerLines)} // Force re-render when data changes
        data={filteredPowerLines}
        style={getLineStyle}
        onEachFeature={(feature, layer) => {
          const { name, capacity_kv } = feature.properties;
          const popupContent = `
            <div>
              <strong>Name:</strong> ${name} <br/>
              <strong>Capacity:</strong> ${capacity_kv} kV <br/>
            </div>
          `;
          layer.bindPopup(popupContent);
        }}
      />
    )
  );
};

export default function Map() {
  const [geoData, setGeoData] = useState(null);
  const [processedGeoData, setProcessedGeoData] = useState(null);
  const [powerLines, setPowerLines] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [powerStationNames, setPowerStationNames] = useState(null);
  // Memoize the GeoJSON data
  const geoJsonData = useMemo(() => geoData, [geoData]);

  const ZoomTracker = () => {
    useMapEvents({
      zoomend: (e) => {
        setZoomLevel(e.target.getZoom());
        // console.log(e.target.getZoom());
      },
    });
    return null;
  };

  useEffect(() => {
    if (typeof powerStationList === "object") {
      setPowerStationNames(powerStationList);
    } else {
      fetch(powerStationList)
        .then((response) => response.json())
        .then((data) => setPowerStationNames(data["powerStationsList"]));
    }
  }, [powerStationList]);

  useEffect(() => {
    if (typeof geoDataRaw === "object") {
      setGeoData(geoDataRaw);
    } else {
      fetch(geoDataRaw)
        .then((response) => response.json())
        .then((data) => setGeoData(data));
    }
  }, []);

  useEffect(() => {
    if (typeof transmissionLines === "object") {
      setPowerLines(transmissionLines);
    } else {
      fetch(transmissionLines)
        .then((response) => response.json())
        .then((data) => setPowerLines(data));
    }
  }, []);

  useEffect(() => {
    if (!geoJsonData || !geoJsonData.features) return;
    const filteredFeatures = geoJsonData.features.filter((feature) => {
      const address = feature.properties.address || "";
      return (
        !address.includes("Northern Territory") &&
        !address.includes("Western Australia") &&
        address
      );
    });
    setProcessedGeoData({ ...geoJsonData, features: filteredFeatures });
  }, [geoJsonData]);

  return (
    <MapContainer center={[-30.5, 148.0]} zoom={5} maxZoom={21}>
      <MapLegend />
      {/* <BasemapLayer name="DarkGray" /> */}
      {/* <FeatureLayer
        url={
          "https://vectortileservices-ap1.arcgis.com/ug6sGLFkytbXYo4f/arcgis/rest/services/LUAL_Network_TR_AUS/VectorTileServer/"
        }
      /> */}
      {zoomLevel < 15 && (
        <TileLayer
          attribution="Sources: Esri © OpenStreetMap contributors, and the GIS User Community"
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          maxZoom={15}
          // attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          // url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
          // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      )}
      {zoomLevel >= 15 && (
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          maxZoom={21}
          // attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )}
      <ZoomTracker />
      <SearchToZoom />
      {/* Conditionally render FeatureLayer only if zoom level is 20 or more */}
      {zoomLevel >= 10 && <Substations zoomLevel={zoomLevel} />}
      {zoomLevel >= 11 && <TransmissionLines zoomLevel={zoomLevel} />}
      {zoomLevel >= 14 && <NetworkTR zoomLevel={zoomLevel} />}
      {zoomLevel >= 16 && <NetworkHV zoomLevel={zoomLevel} />}
      {zoomLevel >= 17 && <Devices zoomLevel={zoomLevel} />}
      {zoomLevel >= 18 && <NetworkLV zoomLevel={zoomLevel} />}
      {zoomLevel >= 19 && <TransmissionPoles zoomLevel={zoomLevel} />}
      {zoomLevel >= 21 && <ExclusionZones zoomLevel={zoomLevel} />}
      {zoomLevel >= 13 && <ZoomedPowerStations zoomLevel={zoomLevel} />}
      <MarkerCluserGroup chunkedLoading disableClusteringAtZoom={9}>
        <PowerStations zoomLevel={zoomLevel} />
      </MarkerCluserGroup>
      {zoomLevel < 11 && <MapWithPowerLines powerLines={powerLines} />}
    </MapContainer>
  );
}

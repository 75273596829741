import { createContext, useState } from "react";

const SettingsContext = createContext();

export default SettingsContext;

export const SettingsProvider = ({ children }) => {
  const [mode, setMode] = useState("dark");
  let contextData = {
    mode: mode,
    setMode: setMode,
  };
  return (
    <SettingsContext.Provider value={contextData}>
      {children}
    </SettingsContext.Provider>
  );
};

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../../context/AuthContext";
import AppTheme from "../shared-theme/AppTheme";
import SiteMarkIcon from "./SiteMarkIcon";

const initialValues = {
  new_password: "",
  re_new_password: "",
};

// const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
const passwordRegExp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;

const userSchema = yup.object().shape({
  new_password: yup
    .string()
    .matches(
      passwordRegExp,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
    )
    .required("Please Enter your password"),
  re_new_password: yup
    .string()
    .required()
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  // contact: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
  // address1: yup.string().required("required"),
});

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
    marginTop: theme.spacing(14),
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function ResetPasswordConfirm(props) {
  let { resetPasswordConfirm } = useContext(AuthContext);
  let { uid, token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = (e) => {
    // console.log(e);
    resetPasswordConfirm(uid, token, e.new_password, e.re_new_password);
    navigate("/login");
  };

  const navigate = useNavigate();

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <SiteMarkIcon />
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Password Reset
          </Typography>
          <Typography
            variant="body2"
            fontStyle={"italic"}
            color="primary.light"
          >
            Reset your password by creating and confirming your new password
            below.
          </Typography>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.new_password}
                    name="new_password"
                    error={!!touched.new_password && errors.new_password}
                    helperText={touched.new_password && errors.new_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type={showPasswordConfirm ? "text" : "password"}
                    label="Confirm Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.re_new_password}
                    name="re_new_password"
                    error={!!touched.re_new_password && errors.re_new_password}
                    helperText={
                      touched.re_new_password && errors.re_new_password
                    }
                    sx={{ gridColumn: "span 2" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt="20px"
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="success"
                    >
                      Reset Password
                    </Button>
                  </Box>
                  <Typography sx={{ textAlign: "center" }}>
                    Already have an account?{" "}
                    <span>
                      <Link
                        onClick={() => navigate("/login")}
                        variant="body2"
                        color="primary.light"
                        sx={{ alignSelf: "center" }}
                      >
                        Login
                      </Link>
                    </span>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}

import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Refresh from "../../components/Refresh";
import SectionHeader from "../../components/SectionHeader";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ConstraintList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let { duid, refresh, duidConstraints, loadDuidConstraintData } =
    useContext(DataContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callDuidConstraintData = async (authTokens, duid) => {
    loadDuidConstraintData(authTokens, duid);
    // setConstraints(_constraintSet);
  };

  const tableColumns = [
    { field: "genconid", headerName: "CONSTRAINT", minWidth: 120, flex: 1.2 },
    { field: "limittype", headerName: "LIMIT TYPE", minWidth: 100, flex: 1 },
    { field: "factor", headerName: "FACTOR", minWidth: 50, flex: 0.5 },
    { field: "constraintvalue", headerName: "VALUE", minWidth: 50, flex: 0.5 },
    {
      field: "genericconstraintweight",
      headerName: "WEIGHT",
      minWidth: 50,
      flex: 0.5,
    },
    { field: "constrainttype", headerName: "TYPE", minWidth: 50, flex: 0.5 },
    { field: "bidtype", headerName: "BID TYPE", minWidth: 75, flex: 0.5 },
    { field: "impact", headerName: "IMPACT", minWidth: 120, flex: 1.2 },
    {
      field: "effectivedate",
      headerName: "EFFECTIVE DATE",
      minWidth: 100,
      flex: 1,
    },
    { field: "risk", headerName: "RISK", minWidth: 120, flex: 1 },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 75,
      flex: 0.75,
    },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 100,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (duidConstraints.length === 0) {
      callDuidConstraintData(authTokens, duid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duidConstraints]);

  return (
    <Box m="5px">
      <Header
        title="Constraint List"
        subtitle="Complete list of Constraints that are linked with specific DUID, Interconnector or Region"
      />
      {(() => {
        if ((duidConstraints.length > 0) & (refresh === false)) {
          return (
            <Box m="5px">
              {/* TABLE VIEW */}
              <SectionHeader
                title="Table View"
                subtitle={`Complete List of Constraint Sets & Ids for Constraints that Impact ${duid}`}
              />

              <Box
                sx={{
                  width: "100%",
                  "& .name-column--cell": {
                    color: colors.grey[100],
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: colors.tableheader,
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: colors.grey[200],
                  },
                }}
              >
                <DataGrid
                  rows={duidConstraints}
                  columns={tableColumns}
                  density={"compact"}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        description: false,
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return (
            <Typography variant="h3" color={colors.grey[100]}>
              No constraints found within SPD Constraint Point Id tables that
              are linked to {duid}.
            </Typography>
          );
        }
      })()}
    </Box>
  );
};

export default ConstraintList;

import { Box, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Topbar from "../scenes/global/Topbar";
import { ColorModeContext, useMode } from "../theme";

const withLogin = (theme, colorMode, props) => {
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Topbar />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          {props.children}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const withoutLogin = (theme, colorMode, props) => {
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          {props.children}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const SubLayout = (props) => {
  let { user } = useContext(AuthContext);
  const [theme, colorMode] = useMode("dark");

  return (
    <div>
      {user
        ? withLogin(theme, colorMode, props)
        : withoutLogin(theme, colorMode, props)}
    </div>
  );
};

export default SubLayout;

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function About() {
  return (
    <Box
      id={"about"}
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "grey.900",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            gutterBottom
            display="flex"
            flexDirection="row"
            sx={{
              justifyContent: "center",
              color: "text.primary",
            }}
          >
            Introducing NEM&nbsp;
            <Typography
              component="small"
              variant="h4"
              gutterBottom
              sx={{ color: "primary.light" }}
            >
              Outages
            </Typography>
          </Typography>

          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Simplifying Outage Management. Real-time Insights and Impact
            Analysis at Your Fingertips.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          sx={{
            width: { sm: "100%", md: "70%" },
            textAlign: { sm: "left", md: "left" },
          }}
        >
          <Typography variant="h6" fontSize="14px" sx={{ color: "grey.400" }}>
            In the dynamic and intricate world of the National Electricity
            Market (NEM), staying abreast of network outages and the resultant
            constraint sets applied by the Australian Energy Market Operator
            (AEMO) is crucial for day-to-day operations. These outages, often
            due to thermal, voltage, or system strength constraints, can
            significantly influence both generators and loads, ultimately
            affecting regional reference prices.
          </Typography>
          <Typography variant="h6" fontSize="14px" sx={{ color: "grey.400" }}>
            Recognizing this critical need for timely outage information, AEMO
            provides the essential Network Outage Schedule (NOS), updated every
            30 minutes with information from Transmission Network Service
            Providers (TNSP&apos;s). This schedule includes data on current, and
            future outages, but can be extremely complicated and tedious to peel
            apart.
          </Typography>
          <Typography variant="h6" fontSize="14px" sx={{ color: "grey.400" }}>
            Traditionally, accessing this vital information meant either
            downloading a CSV file from AEMO&apos;s website or delving into
            complex SQL queries within an internal company database. However,
            both methods require a deep understanding of which constraint sets
            are relevant for specific generators or loads within the NEM. This
            is where the challenge lies: the NOS doesn&apos;t directly indicate
            which network outage will impact a specific generator or load as
            identified by AEMO&apos;s Dispatchable Unit Identifier (DUID).
          </Typography>
          <Typography variant="h6" fontSize="14px" sx={{ color: "grey.400" }}>
            With NEM Outages, simply input a particular DUID, and instantly view
            the ongoing or forecast network outages that may impact your
            generator or load.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppTheme from "../../shared-theme/AppTheme";
import About from "./components/About";
import AppAppBar from "./components/AppAppBar";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Highlights from "./components/Highlights";

export default function MarketingPage(props) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Hero />
      <div>
        <Divider />
        <About />
        <Divider />
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <Footer />
      </div>
    </AppTheme>
  );
}
